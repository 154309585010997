import { Loader } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'

const VoEConsentCard = ({ onPreview, loading }) => {
  return (
    <div
      className={`flex w-full flex-col gap-4 rounded-lg border border-doubleNickel-gray-200 p-5`}
    >
      <div className="text-md flex w-full flex-row items-center justify-between gap-2  font-semibold text-doubleNickel-black">
        Consents
      </div>

      <div className="flex flex-col">
        <div className="flex flex-row items-center gap-3">
          <div className="text-sm font-medium text-doubleNickel-gray-700">
            Driver Consent
          </div>
          <div className="ml-auto">
            {!loading && (
              <Button
                onClick={() => onPreview()}
                variant="subtle"
                text="Review Form"
              />
            )}
            {loading && <Loader className="xs" />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VoEConsentCard
