import { Loader } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'

const VoEDetailsHeader = ({
  showSubmitButton,
  loadingSubmit,
  driverName,
  submitButtonText = 'Submit',
  title = 'Incoming VoE Request',
}) => {
  return (
    <div className="flex w-full flex-row">
      <div className="flex flex-1 flex-col">
        <div className="text-lg font-semibold text-doubleNickel-gray-900">
          {title}
        </div>
        <div className="text-sm text-doubleNickel-gray-600">{driverName}</div>
      </div>
      {showSubmitButton && (
        <div>
          <Button
            loading={loadingSubmit}
            type="submit"
            text={submitButtonText}
          />
        </div>
      )}
    </div>
  )
}

export default VoEDetailsHeader
