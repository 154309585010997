import { InputWrapper } from '@mantine/core'

import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import DateInput from 'src/components/Inputs/DateInput/DateInput'
import { formatSnakeValue } from 'src/lib/formatters'
import TextInput from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/TextInput/TextInput'

const colorByStatus = {
  NOT_STARTED: 'gray',
  IN_PROGRESS: 'blue',
  PENDING_REVIEW: 'orange',
  COMPLETED: 'green',
  EXPIRED: 'red',
}

const RequestInfoCard = ({ voe }) => {
  return (
    <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200">
      <div className="flex flex-col gap-6 p-6">
        <div className="text-md font-semibold text-doubleNickel-gray-900">
          Request Information
        </div>
        <div className="flex flex-row justify-between gap-4">
          <TextInput
            label="Request Number"
            value={voe.voeId}
            editing={false}
            className="flex-1"
          />
          <DateInput
            label="Request Date"
            className="flex-1"
            value={new Date(voe.createdAt)}
            editing={false}
          />
          <InputWrapper
            label="Request Status"
            classNames={{
              root: 'flex flex-col flex-1 gap-3 py-1',
              label: 'text-xs font-medium text-doubleNickel-gray-700',
            }}
          >
            <StyledBadge
              textTransform="capitalize"
              color={colorByStatus[voe.status]}
            >
              {formatSnakeValue(voe.status)}
            </StyledBadge>
          </InputWrapper>
          <TextInput
            label="Assignee"
            className="flex-1"
            value={
              voe.assignee
                ? voe.assignee.firstName + ' ' + voe.assignee.lastName
                : null
            }
            editing={false}
          />
          <DateInput
            label="Completion Date"
            className="flex-1"
            value={voe.completionDate ? new Date(voe.completionDate) : null}
            editing={false}
          />
        </div>
      </div>
    </div>
  )
}

export default RequestInfoCard
