import { Stepper } from '@mantine/core'

const AuditTrailCard = ({ auditTrailLogs }) => {
  if (!auditTrailLogs) {
    return null
  }
  return (
    <div className="flex flex-col gap-6 rounded-lg border border-doubleNickel-gray-200 bg-doubleNickel-gray-50">
      <div className="flex flex-col gap-6 p-6">
        <div className="text-md font-semibold text-doubleNickel-gray-900">
          Audit Trail
        </div>
        <Stepper active={auditTrailLogs.length} orientation="vertical">
          {auditTrailLogs.map((auditTrailLog) => (
            <Stepper.Step
              key={auditTrailLog.auditTrailId}
              label={auditTrailLog.title}
              description={auditTrailLog.message}
            />
          ))}
        </Stepper>
      </div>
    </div>
  )
}

export default AuditTrailCard
